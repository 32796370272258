exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-content-strapi-form-slug-js": () => import("./../../../src/pages/content/{strapiForm.slug}.js" /* webpackChunkName: "component---src-pages-content-strapi-form-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-terms-js": () => import("./../../../src/pages/our-terms.js" /* webpackChunkName: "component---src-pages-our-terms-js" */),
  "component---src-pages-post-strapi-blog-slug-js": () => import("./../../../src/pages/post/{strapiBlog.slug}.js" /* webpackChunkName: "component---src-pages-post-strapi-blog-slug-js" */),
  "component---src-pages-provider-strapi-physician-slug-js": () => import("./../../../src/pages/provider/{strapiPhysician.slug}.js" /* webpackChunkName: "component---src-pages-provider-strapi-physician-slug-js" */),
  "component---src-pages-providers-js": () => import("./../../../src/pages/providers.js" /* webpackChunkName: "component---src-pages-providers-js" */),
  "component---src-pages-service-strapi-service-slug-js": () => import("./../../../src/pages/service/{strapiService.slug}.js" /* webpackChunkName: "component---src-pages-service-strapi-service-slug-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-your-privacy-js": () => import("./../../../src/pages/your-privacy.js" /* webpackChunkName: "component---src-pages-your-privacy-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

